import React from 'react';
import styled from 'styled-components';
import { Loader } from '@bidalihq/ui';

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
`;

const CheckoutLoader = () => {
  return (
    <Container>
      <Loader />
    </Container>
  );
};

export default CheckoutLoader;
