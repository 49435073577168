import Lottie from 'react-lottie-player';
import { Button, Column, Grid, Loader } from '@bidalihq/ui';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import styled from 'styled-components';

import Card from '@/components/card';
import { getFallbackImage } from '@/utils';
import { darkMode, minMedia } from '@/themes';

const Header = dynamic(() => import('@/components/dapp/header'), { ssr: false });
const CategoryPicker = dynamic(() => import('@/components/dapp/category-picker'), { ssr: false });
const Image = dynamic(() => import('@/components/image'), { ssr: false });

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 1em;
  position: relative;

  ${minMedia.xl`
    position: static !important;
  `}

  &::before {
    background:
      linear-gradient(25deg, #EBE6FB, #EBE6FB00 30%),
      linear-gradient(-100deg, #E2FAD5, #E2FAD500 25%),
      linear-gradient(145deg, #F5E8DA, #FAEDFD 16%, #FAEDCF);
    clip-path: ellipse(100% 100% at center top);
    content: '';
    height: 10em;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;

    ${minMedia.xl`
      width: 100vw;
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    `}

    html.dark &{
      background:
        linear-gradient(25deg,#6148AE,#6148AE00 30%),
        linear-gradient(-100deg,#997DB0,#997DB000 25%),
        linear-gradient(145deg,#C1BE90,#C48EA9 16%,#5A6ABF);
    }
  }
`;

const Title = styled.h1`
  color: #29333e;
  flex-shrink: 0;
  letter-spacing: -1px;
  margin: 0;
  max-width: calc(100% - 2em);
  mix-blend-mode: hard-light;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  html.dark &{
    color: white;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${minMedia.xl`
    flex-direction: row;
    flex: 1;
  `}
`;

const Brand = styled(Card)`
  cursor: pointer;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const EmptyState = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  text-align: center;
`;

const Animation = styled(Lottie)`
  margin-bottom: 2em;
  width: 6em;

  html.dark &{
    filter: invert(1) brightness(2) opacity(.8);
  }
`;

const NoResults = styled.p`
  html.dark &{
    color: ${({ theme }) => theme.colors.typography.inverted};
  }
`;

const BrandsBrowser = ({
  brands,
  categories,
  category,
  country,
  isLoading,
  onBrandClick = () => {},
  onCategoryChange = () => {},
  onCountryChange = () => {},
  onReset = () => {},
  onSearchChange = () => {},
  search
}) => {
  const t = useTranslations('categories');
  const bT = useTranslations('brandGrid');

  const handleBrandClick = brand => () => {
    onBrandClick(brand);
  };

  return (
    <Container>
      <Title>{t(category || 'giftcard')}</Title>
      <Header
        countryId={country.id}
        onChangeCountry={onCountryChange}
        onChangeSearch={onSearchChange}
        search={search} />
      <Content>
        <CategoryPicker
          categories={categories}
          selected={category}
          onChange={onCategoryChange} />
        {isLoading && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        {!isLoading && brands.length > 0 && (
          <Grid className="grid-cols-2 flex-1" columns={[2, 3]} style={{ gridAutoRows: 'max-content' }}>
            {brands.map(brand => (
              <Column key={brand.id} data-testid="brand">
                <Brand backgroundColor={brand.backgroundColor} onClick={handleBrandClick(brand)}>
                  <Image
                    alt={brand.name}
                    fallback={getFallbackImage(brand.type)}
                    height={150}
                    src={brand.imageUrl}
                    unoptimized
                    width={250} />
                </Brand>
              </Column>
            ))}
          </Grid>
        )}
        {!isLoading && brands.length === 0 && (
          <EmptyState>
            <Animation
              animationData={require('@/lottie-files/cart-x.json')}
              play />
            <NoResults>
              {bT.rich('noResultsInCountry', {
                country: country?.name,
                strong: children => <strong>{children}</strong>
              })}
            </NoResults>
            <Button onClick={onReset} variant="accent">
              {bT('browseAll')}
            </Button>
          </EmptyState>
        )}
      </Content>
    </Container>
  );
};

export default BrandsBrowser;
