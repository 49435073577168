import React, { useEffect, useState } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { observer } from 'mobx-react-lite';
import { darken } from 'polished';
import { AlertTriangle } from 'styled-icons/feather';
import _ from 'lodash';
import { useMediaQuery } from '@react-hook/media-query';

import countries from '@data/countries';
import cats from '@data/categories';
import useBrands from '@/hooks/use-brands';
import Footer from '@/components/dapp/footer';
import BrandDetails from '@/components/dapp/brand-details';
import BrandsBrowser from '@/components/dapp/brands-browser';
import { parseIPAddress, isUnsupportedBrowser } from '@/utils';
import { getFallbackCountry } from '@/utils/country';
import { minMedia } from '@/themes';
import { useStore } from '@/stores/store-provider';
import useDappProvider from '@/hooks/use-dapp-provider';
import CheckoutLoader from '@/components/dapp/checkout-loader';
import config from '@/config';

const CartButton = dynamic(() => import('@/components/cart-button'), { ssr: false });
const Checkout = dynamic(() => import('@/components/dapp/checkout'), { ssr: false, loading: () => <CheckoutLoader /> });

const GlobalStyle = createGlobalStyle`
  body {
    html.dark &{
      background-color: ${({ theme }) => theme.colors.typography.primary};
    };
  }
`;

const CartContainer = styled.div`
  position: absolute;
  right: 1em;
  top: .5em;
  z-index: 10;

  ${({ isTestNet }) => isTestNet && css`
    top: 3.5em;

    ${minMedia.xs`
      top: 2.5em;
    `}
  `}

  svg {
    html.dark &{
      fill: ${({ theme }) => theme.colors.typography.inverted};
    }
  }
`;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: auto;
  position: relative;
`;

const TestNetBanner = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.status.warning};
  color: ${({ theme }) => darken(.5, theme.colors.status.warning)};
  display: flex;
  font-size: .85em;
  padding: .5em 1em;
  position: relative;
  justify-content: center;
  z-index: 20;

  svg {
    width: 1em;
    stroke-width: 2px;
    margin-right: .5em;
  }

  ${minMedia.xl`
    border-radius: 9999px;
    box-shadow: ${({ theme }) => theme.shadows.primary};
    display: inline-flex;
    left: 50%;
    position: relative;
    transform: translateY(1em) translateX(-50%);
  `}
`;

const Dapp = ({ initialCountry, category }) => {
  const router = useRouter();
  const [countryId, setCountryId] = useState(initialCountry.id);
  const [country, setCountry] = useState(initialCountry);
  const [clientCategory, setCategory] = useState(category?.slug);
  const [search, setSearch] = useState(router.query.search);
  const [brand, setBrand] = useState(null);
  const store = useStore();
  const dappProvider = useDappProvider();
  const isTestNet = config.commerceEnv !== 'production';
  const OSDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const { brands, categories, category: responseCategory, isLoading } = useBrands({
    countryId: countryId || initialCountry.id,
    category: clientCategory || category?.slug,
    search: search || router.query.search
  });

  const handleCountryChange = ({ value }) => {
    setCountryId(value);
    setCategory(undefined);
  };

  const handleSearchChange = value => {
    setSearch(value);
  };

  const handleReset = () => {
    setSearch(undefined);
    setCategory(undefined);
  };

  const handleBrandClick = brand => {
    setBrand(brand);
  };

  useEffect(() => {
    if (Array.isArray(dappProvider?.limitToCountries) && !dappProvider?.limitToCountries.includes(initialCountry.id)) {
      setCountryId(dappProvider.limitToCountries[0]);
    }
  }, [dappProvider, initialCountry.id]);

  useEffect(() => {
    if (dappProvider && dappProvider.theme) {
      document.querySelector('html').classList.add(dappProvider.theme);
    } else if (OSDarkMode) {
      document.querySelector('html').classList.add('dark');
    } else {
      document.querySelector('html').classList.remove('dark');
    }
  }, [dappProvider, OSDarkMode]);

  useEffect(() => {
    if (countryId && countryId !== initialCountry.id) {
      setCountry(countries.find(c => c.id === countryId));
    }
  }, [initialCountry.id, countryId]);

  useEffect(() => {
    if (clientCategory && !categories.includes(clientCategory)) {
      setCategory(undefined);
    }

    if (typeof window !== 'undefined' && typeof window.scrollTo === 'function') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      document.documentElement.scrollTop = 0;
    }
  }, [categories, clientCategory]);

  useEffect(() => {
    // window.valora = {
    //   paymentCurrency: 'CUSD',
    //   phoneNumber: '12015551234',
    //   balances: {
    //     CUSD: '24.2500000',
    //     CEUR: '44.2500000'
    //   },
    //   onPaymentRequest: (paymentRequest) => {
    //     const payload = { method: 'onPaymentRequest', data: paymentRequest };
    //     console.log(JSON.stringify(payload, null, 2));
    //   },
    //   openUrl: (url) => {
    //     const payload = { method: 'openUrl', data: { url } };
    //     console.log(JSON.stringify(payload, null, 2));
    //   }
    // };
    // window.bidaliProvider = {
    //   theme: 'dark',
    //   name: 'Some Wallet',
    //   paymentCurrencies: ['stellar', 'testusdcstellar'],
    //   email: 'provider@bidali.com',
    //   phoneNumber: '12015551234',
    //   limitToCountries: ['US', 'CA'],
    //   balances: {
    //     stellar: '8241.0149411',
    //     testusdcstellar: '10.2500000'
    //   },
    //   onPaymentRequest: (paymentRequest) => {
    //     const payload = { method: 'onPaymentRequest', data: paymentRequest };
    //     console.log(JSON.stringify(payload, null, 2));
    //   },
    //   openUrl: (url) => {
    //     const payload = { method: 'openUrl', data: { url } };
    //     console.log(JSON.stringify(payload, null, 2));
    //   }
    // };
  }, []);

  return (
    <Wrapper>
      {isTestNet &&
        <TestNetBanner>
          <AlertTriangle />
          This is a testnet environment and doesn&apos;t use real currency
        </TestNetBanner>
      }
      <GlobalStyle />
      {!store.showDappCheckout &&
        <CartContainer isTestNet={isTestNet}>
          <CartButton />
        </CartContainer>
      }
      {!brand && !store.showDappCheckout &&
        <BrandsBrowser
          brands={brands}
          categories={categories}
          category={clientCategory || responseCategory || category?.slug}
          country={country}
          isLoading={isLoading}
          onBrandClick={handleBrandClick}
          onCategoryChange={setCategory}
          onCountryChange={handleCountryChange}
          onReset={handleReset}
          onSearchChange={handleSearchChange}
          search={search}
        />
      }
      {brand && !store.showDappCheckout &&
        <BrandDetails
          key={brand?.id}
          brand={brand}
          onGoBack={countryId => {
            setBrand(null);
            setCountryId(countryId);
          }}
        />
      }
      {store.showDappCheckout &&
        <Checkout />
      }
      {!store.showDappCheckout && <Footer />}
    </Wrapper>
  );
};

export async function getServerSideProps ({ locale, query, req }) {
  if (isUnsupportedBrowser(req.headers['user-agent'])) {
    return {
      redirect: {
        statusCode: 302,
        destination: '/unsupported-platform'
      }
    };
  }

  const countryId = query.country && query.country.toUpperCase();
  const category = cats.data.find(c => c.name === query?.category || c.slug === query?.category) || null;
  const ipAddress = query.ip || parseIPAddress(req);
  const initialCountry = countryId
    ? countries.find(c => c.id === countryId)
    : await getFallbackCountry(ipAddress, req, '/dapp');

  return {
    props: {
      hideCurrencies: true,
      messages: require(`@/locales/${locale}.json`),
      hideFooter: true,
      hideHeader: true,
      initialCountry,
      category
    }
  };
}

export default observer(Dapp);
