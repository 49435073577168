import omit from 'lodash.omit';
import { getCurrencyByDisplaySymbol } from '@/utils';

export default function useDappProvider () {
  if (typeof window !== 'undefined' && window.valora) {
    const paymentCurrency = getCurrencyByDisplaySymbol(window.valora.paymentCurrency);
    const balances = {};

    Object.keys(window.valora?.balances || {})
      .forEach(symbol => {
        const currency = getCurrencyByDisplaySymbol(symbol);
        balances[currency.protocol] = window.valora.balances[symbol];
      });

    return {
      ...omit(window.valora, 'paymentCurrency'),
      name: 'Valora',
      balances,
      paymentCurrencies: [paymentCurrency?.protocol]
    };
  }

  if (typeof window !== 'undefined' && window.bidaliProvider) {
    return window.bidaliProvider;
  }

  return null;
}
