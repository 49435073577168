import { useEffect, useState } from 'react';

import { useStore } from '@/stores/store-provider';
import Bugsnag from '@/bugsnag';
import { formatForCurrency } from '@/utils';

export default function useDappHandlers () {
  const [awaitingWalletPayment, setAwaitingWalletPayment] = useState(false);
  const [paymentDetectedClientSide, setPaymentDetectedClientSide] = useState(false);
  const store = useStore();

  useEffect(() => {
    store.setPaymentSubmitted(paymentDetectedClientSide);
  }, [paymentDetectedClientSide]); // eslint-disable-line react-hooks/exhaustive-deps

  if (typeof window !== 'undefined' && window.valora) {
    return {
      awaitingWalletPayment,
      paymentDetectedClientSide,

      onClose: () => {
        console.log('calling onClose on dapp');
      },

      onPaymentRequest: async () => {
        setAwaitingWalletPayment(true);

        const paymentRequest = {
          amount: store.quote.amount,
          // In the BidaliProvider we don't send 'currency', we send 'protocol' and 'symbol'
          currency: store.selectedCurrency.displaySymbol.toUpperCase(), // Valora currently expects this
          symbol: store.selectedCurrency.displaySymbol.toUpperCase(),
          protocol: store.selectedCurrency.protocol,
          address: store.quote.address,
          chargeId: store.charge.id,
          description: store.charge.description,
          extraId: store.quote.extraId,
          extraIdName: store.selectedCurrency.extraIdName,
          lineItems: store.items.map(item => ({
            name: item.product.name,
            quantity: item.quantity,
            amount: formatForCurrency({ amount: item.amount, currency: item.product.currency }),
            image: item.brand.imageUrl
          }))
        };

        console.log(`calling onPaymentRequest on celo dapp with: ${JSON.stringify(paymentRequest, null, 2)}`);

        try {
          window.valora.paymentSent = () => {
            setPaymentDetectedClientSide(true);
            setAwaitingWalletPayment(false);
          };

          window.valora.paymentCancelled = () => {
            setAwaitingWalletPayment(false);
          };
          window.valora.onPaymentRequest(paymentRequest);
        } catch (e) {
          console.log(e);
          console.log(e.message);
          Bugsnag.notify(e, { metaData: { location: 'setupHandlerForCeloDapp:onPaymentRequest' } });
        }
      },

      openUrl: (url) => {
        console.log(`calling openUrl on dapp with: ${url}`);
        window.valora.openUrl(url);
      }
    };
  }

  if (typeof window !== 'undefined' && window.bidaliProvider) {
    return {
      awaitingWalletPayment,
      paymentDetectedClientSide,

      onClose: () => {
        console.log('calling onClose on dapp');
      },

      onPaymentRequest: async () => {
        setAwaitingWalletPayment(true);

        const paymentRequest = {
          amount: store.quote.amount,
          symbol: store.selectedCurrency.displaySymbol.toUpperCase(),
          protocol: store.selectedCurrency.protocol,
          address: store.quote.address,
          chargeId: store.charge.id,
          description: store.charge.description,
          extraId: store.quote.extraId,
          extraIdName: store.selectedCurrency.extraIdName,
          lineItems: store.items.map(item => ({
            name: item.product.name,
            quantity: item.quantity,
            amount: formatForCurrency({ amount: item.amount, currency: item.product.currency }),
            image: item.brand.imageUrl
          }))
        };

        console.log(`calling onPaymentRequest on bidaliProvider with: ${JSON.stringify(paymentRequest, null, 2)}`);

        try {
          window.bidaliProvider.paymentSent = () => {
            setPaymentDetectedClientSide(true);
            setAwaitingWalletPayment(false);
          };

          window.bidaliProvider.paymentCancelled = () => {
            setAwaitingWalletPayment(false);
          };

          window.bidaliProvider.onPaymentRequest(paymentRequest);
        } catch (e) {
          console.log(e);
          console.log(e.message);
          Bugsnag.notify(e, { metaData: { location: 'setupHandlerForBidaliProvider:onPaymentRequest' } });
        }
      },

      openUrl: (url) => {
        console.log(`calling openUrl on dapp with: ${url}`);
        window.bidaliProvider.openUrl(url);
      }
    };
  }

  return {};
}
