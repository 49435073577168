import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import useSWR from 'swr';
import styled, { createGlobalStyle } from 'styled-components';
import { Button } from '@bidalihq/ui';
import { ArrowLeft } from 'styled-icons/feather';

import BrandStore from '@/stores/brand-store';
import Brand from '@/components/brand';
import { useStore } from '@/stores/store-provider';
import { fetchBrandNew } from '@/api';
import { darkMode, minMedia, media } from '@/themes';
import useDappHandlers from '@/hooks/use-dapp-handlers';

const Container = styled.div`
  background: white;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  margin-bottom: 2rem;
  padding: 1rem;
  position: relative;
  z-index: 3;

  html.dark &{
    background: ${({ theme }) => theme.colors.typography.primary};
  }

  ${minMedia.xl`
    border-radius: 0 0 .5em .5em;
  `}

  &::before {
    background:
      linear-gradient(25deg, #EBE6FB, #EBE6FB00 30%),
      linear-gradient(-100deg, #E2FAD5, #E2FAD500 25%),
      linear-gradient(145deg, #F5E8DA, #FAEDFD 16%, #FAEDCF);
    clip-path: ellipse(100% 100% at center top);
    content: '';
    height: 5em;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;

    ${minMedia.sm`
      top: -1.5em;
    `}

    html.dark &{
      background:
        linear-gradient(25deg,#6148AE,#6148AE00 30%),
        linear-gradient(-100deg,#997DB0,#997DB000 25%),
        linear-gradient(145deg,#C1BE90,#C48EA9 16%,#5A6ABF);
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  [class*="brand__BrandContainer"] {
    position: relative;
    z-index: 1;
  }

  .select {
    &__control, &__menu {
      z-index: 10 !important;
      box-shadow: ${({ theme }) => theme.shadows.primary} !important;
    }
  }

  html.dark &{
    .select {
      &__control, &__menu {
        background: #394150 !important;
        color: ${({ theme }) => theme.colors.typography.inverted} !important;

        &--is-focused, &:hover {
          border-color: transparent !important;
        }
      }

      &__single-value {
        color: ${({ theme }) => theme.colors.typography.inverted} !important;
      }
    }

    [class*="brand__Content"],
    [class*="brand__BreadCrumbContainer"],
    [class*="brand__SeoText"],
    [class*="brand__CountryWarning"],
    [class*="brand__ImportantNotes"],
    [class*="brand__Description"] {
      color: ${({ theme }) => theme.colors.typography.inverted}DD !important;

      a {
        color: ${({ theme }) => theme.colors.typography.inverted} !important;
      }
    }

    [class*="brand__Content"],
    [class*="app__MainContent"] {
      background: ${({ theme }) => theme.colors.typography.secondary} !important;
      color: ${({ theme }) => theme.colors.typography.inverted}DD !important;

      a {
        color: ${({ theme }) => theme.colors.typography.inverted} !important;
      }
    }

    @media (min-width: 616px) {
      [class*="brand__Content"] {
        background-color: transparent !important;
      }
    }

    [class*="brand__CustomAmountSwitch"] {
      color: ${({ theme }) => theme.colors.typography.inverted} !important;
    }

    [class*="brand__ImportantNotes"] {
      background: rgba(0, 0, 0, .1) !important;
      border-top-color: rgba(0, 0, 0, .1);
      border-right-color: rgba(0, 0, 0, .1);
      border-bottom-color: rgba(0, 0, 0, .1);

      p {
        background: none !important;
      }
    }

    [class*="brand__ReadMore"] {
      color: ${({ theme }) => theme.colors.typography.inverted} !important;
    }

    [class*="brand__BrandName"] {
      color: ${({ theme }) => theme.colors.typography.inverted} !important;
    }

    [class*="brand__Tag"] {
      background: rgba(0, 0, 0, .25);
    }

    [class*="brand__SectionName"] {
      color: ${({ theme }) => theme.colors.typography.inverted} !important;

      &.selected {
        border-bottom-color: ${({ theme }) => theme.colors.typography.inverted} !important;
      }
    }
  }
`;

const BrandDetails = ({ brand, onGoBack }) => {
  const t = useTranslations('brands');
  const router = useRouter();
  const cart = useStore();
  const { openUrl } = useDappHandlers();
  const { data: updatedBrand, error } = useSWR([brand.country.slug, brand.slug], fetchBrandNew, { fallbackData: brand, revalidateOnMount: true });

  const handleGoBack = () => {
    onGoBack(brand.country.id);
  };

  const [store] = useState(() => {
    if (brand) {
      return new BrandStore(brand, cart, null);
    }

    return null;
  });

  const seoText = t('seoText', { type: brand.type, brandName: brand.name });
  const seoDescription = `${seoText} Bitcoin (BTC), USDC, USDT (Tether), XLM (Stellar), Ethereum, Litecoin, Dai, Polygon (MATIC), Fantom (FTM), Avalanche (AVAX), Solana (SOL), Algorand (ALGO) ${t('manyOtherCoins')}`;

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.scrollTo === 'function') {
      window.scrollTo(0, 0);
    } else {
      document.documentElement.scrollTop = 0;
    }
  }, [updatedBrand]);

  return (
    <>
      <Container className="brand-details-container">
        <Button
          icon={<ArrowLeft size={16} />}
          onClick={handleGoBack}
          size="small"
          style={{ position: 'relative', zIndex: 2 }}
          variant="ghost"
        >
          Go back
        </Button>
        <Brand
          brand={updatedBrand}
          isDapp
          onClickURL={openUrl}
          onGoBack={handleGoBack}
          key={updatedBrand.id}
          products={updatedBrand.products}
          router={router}
          seoText={seoDescription}
          store={store}
        />
      </Container>
      <GlobalStyle />
    </>
  );
};

export default BrandDetails;
